<template>
  <div class="wrap-infodetails">
    <div class="deta-header">
      <span>{{ dataList.Title }}</span>
      <span>{{ dataList.CreateTime }}</span>
    </div>
    <div style="padding: 10px">
      <div class="deta-content">
        <p>
          <a
            href="#"
            target="_blank"
            style="background-color: rgb(255, 255, 255); font-size: 14px"
            >{{ dataList.Title }}</a
          >
          <!-- <a
            href="http://custom.huzhou.gov.cn/DFS//file/2021/06/29/20210629165228774hnlqye.pdf"
            target="_blank"
            style="background-color: rgb(255, 255, 255); font-size: 14px;"
            >{{ dataList.Title }}</a
          > -->
        </p>

        <span>附件：</span>
        <a href="#"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
      title: "",
    };
  },
  methods: {
    //转化json
    switch() {
      this.dataList = this.$route.query.row;
      //    console.log('标题时间：',this.$route);
      if (this.dataList) {
        this.dataList = JSON.parse(this.dataList);
        console.log("标题时间：", this.dataList);
      }
    },
    //模拟ajax请求 这里应当是通过type属性获取不同内容
    async getDataList() {
        console.log("父传子的：", this.$route.query);
      let type = this.$route.query.type;
      let Id = this.$route.query.Id;
      let res = await this.$ajax.post("/api/info/search", {
        name: Id,type:type
      });
      //   console.log("details得到的：", res);
      this.dataList = res.data.list[0];
      // console.log(this.dataList);

    },
  },
  mounted() {
    this.getDataList();
    
  },
};
</script>

<style scoped>
.wrap-infodetails {
  background: #ffffff;
}
.deta-header {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #f4f4f4;
}
.deta-header span:first-child {
  font-size: 20px;
  display: block;
}
.deta-header span:last-child {
  font-size: 12px;
}
.deta-content {
  padding: 0 100px 0 100px;
}
</style>